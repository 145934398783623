import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ConfirmModal,
  DataGrid,
  GridActionsCellItem,
  GridCellParams,
  GridEnrichedColDef,
} from '@microservices/wiskey-react-components'
import { ContentCopy, Delete, Edit } from '@mui/icons-material'
import { Grid } from '@mui/material'

import { CONFIG_TOOLTIPS_COLUMNS, DEFAULT_HEADER_STYLES_CLASSNAME, PINNED_COLUMN } from '@constants'

type AllTooltipsTableProps = {
  rows: any[]
  loading: boolean
  onDelete: (id: number) => void
  onCopy: (data: any) => void
  onEdit: (id: number) => void
}

export const AllTooltipsTable: FC<AllTooltipsTableProps> = ({
  rows,
  loading,
  onDelete,
  onCopy,
  onEdit,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [currentRowId, setCurrentRowId] = useState<number>()
  const { t } = useTranslation()

  const handleCopy = (params: GridCellParams) => {
    const { id, title } = params.row
    onCopy?.({ id, title })
  }

  const handleEdit = (params: GridCellParams) => {
    onEdit?.(params.row.id)
  }

  const handleClickDelete = (params: GridCellParams) => {
    if (params.row.id) {
      setShowDeleteModal(true)
      setCurrentRowId(params.row.id)
    }
  }

  const handleDelete = () => {
    if (currentRowId) {
      onDelete?.(currentRowId)
      setShowDeleteModal(false)
    }
  }

  const handleClose = () => setShowDeleteModal(false)

  const columnsWithActions = [
    ...CONFIG_TOOLTIPS_COLUMNS,
    {
      field: 'actions',
      type: 'actions',
      align: 'center',
      minWidth: 120,
      resizable: false,
      hideable: false,
      headerClassName: `${DEFAULT_HEADER_STYLES_CLASSNAME}`,
      pinnedColumn: PINNED_COLUMN.RIGHT,
      getActions: (params: GridCellParams) => [
        <GridActionsCellItem
          key={params.id}
          icon={<ContentCopy />}
          label='Copy'
          showInMenu={false}
          onClick={() => handleCopy(params)}
        />,
        <GridActionsCellItem
          key={params.id}
          icon={<Edit />}
          label='Edit'
          showInMenu={false}
          onClick={() => handleEdit(params)}
        />,
        <GridActionsCellItem
          key={params.id}
          icon={<Delete />}
          label='Delete'
          showInMenu={false}
          onClick={() => handleClickDelete(params)}
        />,
      ],
    } as GridEnrichedColDef,
  ]

  return (
    <Grid container p={1}>
      {showDeleteModal && (
        <ConfirmModal
          actionBtnText={t('modal.delete.btn')}
          containerStyle={{ px: 0.5, py: 0.5, borderRadius: 0 }}
          isShow={showDeleteModal}
          text={t('modal.delete.warning')}
          title={t('modal.delete.title')}
          onClose={handleClose}
          onConfirm={handleDelete}
        />
      )}
      <Grid container item>
        <DataGrid
          hideFooter
          columns={columnsWithActions}
          loading={loading}
          rows={rows}
          sx={{ borderRadius: 0 }}
        />
      </Grid>
    </Grid>
  )
}
